<template>
  <div id="footer-griffor">
    <!-- Instagram -->
    <a
      href="https://www.instagram.com/p/CbDLQhWA1dJ/"
      target="_blank"
      style="margin-right: 2px"
    >
      <img
        :src="require(`@/assets/misc/instagram-icon.png`)"
        alt="La page Instagram officielle du Test de pureté !"
        width="32"
        height="32"
      >
    </a>
    <!-- Tiktok -->
    <a
      href="https://www.tiktok.com/@alexy_yes"
      target="_blank"
      style="margin-right: 2px"
    >
      <img
        :src="require(`@/assets/misc/tiktok-icon.png`)"
        alt="Le tiktok du Test de pureté !"
        width="32"
        height="32"
      >
    </a>
    <!-- Twitter -->
    <a
      href="https://twitter.com/GrifforTest"
      target="_blank"
    >
      <img
        :src="require(`@/assets/misc/twitter-icon.png`)"
        alt="La page Twitter officielle du Test de pureté !"
        width="32"
        height="32"
      >
    </a>
    &copy;<a
      rel="noreferrer"
      target="_blank"
      href="http://test-griffor.com"
    >Griffor -
    </a>
    {{ $t("contact-us") }} contact@test-griffor.com
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
};
</script>
