<template>
  <div
    id="inner-header"
    class="inner"
  >
    <h3
      class="masthead-brand grifforlogo"
      style="font-size: 78px; margin-top: -15px"
    >
      <a
        style="text-decoration: none"
        @click="$router.push({ name: 'home', params: { lang: $i18n.locale } })"
      >GRIFFOR</a>
    </h3>
  </div>
  <ul
    class="nav masthead-nav"
    style="white-space: nowrap"
  >
    <li
      id="lang-btq"
      class="logoflag"
      style="width: 150px !important"
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        "
      >
        <div
          class="circle"
          style="animation-delay: 0s; background-color: #d9534f"
        />
        <div
          class="circle"
          style="animation-delay: 1s; background-color: #f0ad4e"
        />
        <a
          href="https://griffor.company.site"
          rel="noreferrer"
          target="blank_"
          style="z-index: 100"
        >
          <img
            :src="require(`@/assets/flags/boutique_alt.png`)"
            alt="btq"
          >
        </a>
      </div>
    </li>
    <li
      id="lang-fr"
      :class="$i18n.locale === 'fr' ? 'active' : ''"
      class="logoflag"
    >
      <a
        @click="$router.push({ name: route, params: { lang: 'fr' } })"
      ><img
        :src="require(`@/assets/flags/fr.png`)"
        alt="fr"
      ></a>
    </li>
    <li
      id="lang-en"
      :class="$i18n.locale === 'en' ? 'active' : ''"
      class="logoflag"
    >
      <a
        @click="$router.push({ name: route, params: { lang: 'en' } })"
      ><img
        :src="require(`@/assets/flags/en.png`)"
        alt="en"
      ></a>
    </li>
    <li
      id="lang-chti"
      :class="$i18n.locale === 'chti' ? 'active' : ''"
      class="logoflag"
    >
      <a
        @click="$router.push({ name: route, params: { lang: 'chti' } })"
      ><img
        :src="require(`@/assets/flags/chti.png`)"
        alt="chti"
      ></a>
    </li>
    <li
      id="lang-uk"
      :class="$i18n.locale === 'uk' ? 'active' : ''"
      class="logoflag"
    >
      <a
        @click="$router.push({ name: route, params: { lang: 'uk' } })"
      ><img
        :src="require(`@/assets/flags/uk.png`)"
        alt="uk"
      ></a>
    </li>
    <li
      id="lang-ru"
      :class="$i18n.locale === 'ru' ? 'active' : ''"
      class="logoflag"
    >
      <a
        @click="$router.push({ name: route, params: { lang: 'ru' } })"
      ><img
        :src="require(`@/assets/flags/ru.png`)"
        alt="ru"
      ></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Menu",
  props: {
    route: {
      require: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.2);
    opacity: 0.5;
  }
  to {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.circle {
  border-radius: 50%;
  width: 200px;
  height: 63px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}
</style>
