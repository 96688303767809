<template>
  <div
    id="testAlternatif"
    class="test-alternatif-size"
  >
    <div
      id="colorLabelType"
      :class="scores.kharma.label"
      class="test-alternatif-size"
    >
      <div
        id="test-alternatif-container"
        class="test-alternatif-size"
      >
        <div id="testAlternatifTitle">
          <p>Test de pureté - Version alternative</p>
        </div>
        <ul v-if="questionIndex !== 0">
          <li
            id="sexe"
            :class="scores.sexe.label"
          >
            sexe<b>{{ Math.round(scores.sexe.value) }}<br>{{
              scores.sexe.label
            }}</b>
          </li>
          <li
            id="alcool"
            :class="scores.alcool.label"
          >
            alcool<b>{{ Math.round(scores.alcool.value) }}<br>{{
              scores.alcool.label
            }}</b>
          </li>
          <li
            id="drogue"
            :class="scores.drogue.label"
          >
            drogue<b>{{ Math.round(scores.drogue.value) }}<br>{{
              scores.drogue.label
            }}</b>
          </li>
          <li
            id="hygiene"
            :class="scores.hygiene.label"
          >
            hygiene<b>{{ Math.round(scores.hygiene.value) }}<br>{{
              scores.hygiene.label
            }}</b>
          </li>
          <li
            id="morale"
            :class="scores.morale.label"
          >
            morale<b>{{ Math.round(scores.morale.value) }}<br>{{
              scores.morale.label
            }}</b>
          </li>
          <li
            id="kharma"
            :class="scores.kharma.label"
          >
            kharma<b>{{ Math.round(scores.kharma.value) }}<br>{{
              scores.kharma.label
            }}</b>
          </li>
        </ul>
        <div v-if="questionIndex < questions.length - 1">
          <p id="testAlternatifQuestion">
            {{ questions[questionIndex].question }}
          </p>

          <div id="reponses">
            <div
              v-for="(points, value) in questions[questionIndex].answers"
              :key="value"
            >
              <p>
                <button
                  type="button"
                  @click="submit(value)"
                >
                  {{ value }}
                </button>
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <big>
              <big>
                C’est fini :<br>Vous êtes <b>{{ scores.kharma.label }}</b> ({{
                  Math.round(scores.kharma.value)
                }}
                points)<br><br>
              </big>
            </big>
          </div>

          <div>
            <!--<a
              href="https://www.instagram.com/p/CbDLQhWA1dJ/"
              target="_blank"
              style="margin-right: 2px"
            >
              <h4
                style="
                  margin-top: 0px !important;
                  margin-bottom: 5px !important;
                "
              >
                Rejoins
                <span
                  style="font-family: NeutonExtraBold; font-size: 22px"
                >Griffor</span><br>
                sur
                <img
                  :src="require(`@/assets/misc/instagram-icon.png`)"
                  alt="La page Instagram officielle du Test de pureté !"
                  width="32"
                  height="32"
                >
              </h4>
            </a>
              <br>
              -->

            <div>
              <h4
                style="
                  margin-top: 0px !important;
                  margin-bottom: 5px !important;
                "
              >
                <!--Des bobs, capotes, sweats
                <span
                  style="font-family: NeutonExtraBold; font-size: 22px"
                >Griffor</span>
                <br>
                avec TON score dessus !
              </h4>
              <a
                href="https://boutique.test-griffor.com"
                target="_blank"
              >
                <img
                  style="width: 75%; max-width: 310px"
                  src="/images/boutique_ad.jpg"
                ></a>-->

                <span
                  style="font-family: NeutonExtraBold; font-size: 22px"
                >Griffor</span>

                s'incruste en
                <br>
                <strong>BACKSTAGE D'ARTISTES </strong>
              </h4>
              <a
                href="https://www.instagram.com/p/CbDLQhWA1dJ/"
                target="_blank"
              >
                <img
                  style="width: 75%; max-width: 310px"
                  src="/images/orelsan_rennes.webp"
                ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TestAlternatif",

  data() {
    return {
      /**
       * Compteur de point pour chaque catégorie de question.
       * @type {Object.<string, number>}
       */
      scores: {
        sexe: { value: 0, label: "pur" },
        alcool: { value: 0, label: "pur" },
        drogue: { value: 0, label: "pur" },
        hygiene: { value: 0, label: "pur" },
        morale: { value: 0, label: "pur" },
        kharma: { value: 0, label: "pur" },
      },
      /**
       * Coefficient pour chaque catégorie qui permet de normalisé les points (en
       * fixant le maximum)
       * @type {Object.<string, number>}
       */
      coefs: {
        sexe: 1.96,
        alcool: 2.78,
        drogue: 3.44,
        hygiene: 3.22,
        morale: 1.85,
        kharma: 0.5,
      },
      /**
       * Noms et position des levels de pureté (attention, les noms sont associés aux
       * classes CSS). Le nombre correspond au seuil de points dans une catégorie pour
       * activer le niveau.
       *
       * @type {Object.<string, number>}
       */
      levels: {
        divin: 20,
        saint: 10,
        pur: 0,
        normal: -10,
        immoral: -15,
        impur: -20,
        indecent: -25,
        malsain: -30,
        ignoble: -35,
        vicieux: -40,
        infame: -45,
        deprave: -50,
        dangereux: -61,
        inhumain: -72,
        demon: -85,
        diabolique: -200,
      },
      questions: [
        {
          categorie: "morale",
          question: "Avez-vous déjà ri du malheur de quelqu’un ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "morale",
          question:
            "Avez vous déjà ri d’une personne mentalement " +
            "ou physiquement handicapée ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà embrassé quelqu’un ?",
          answers: { oui: 0, non: 1 },
        },
        {
          categorie: "sexe",
          question: "Vous-êtes vous déjà masturbé ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Jusqu’à l’orgasme ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "hygiene",
          question: "Vous est-il arrivé de ne pas vous laver ?",
          answers: {
            "4 jours ou +": -6,
            "3 jours": -4,
            "2 jours": -2,
            "+ de 24h": 0,
            "je me lave tous les jours": 5,
          },
        },
        {
          categorie: "alcool",
          question: "Avez-vous déjà bu de l’alcool ?",
          answers: { oui: -1, non: 2 },
        },
        {
          categorie: "alcool",
          question: "Avez-vous déjà été ivre ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "alcool",
          question: "Avez-vous déjà participé à des jeux à boire ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "alcool",
          question: "Avez-vous déjà vomi à cause de l’alcool ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "alcool",
          question: "Avez-vous déjà vomi sur vous ou sur quelqu’un d’autre ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "alcool",
          question: "Etes-vous déjà tombé(e) parce que vous étiez trop ivre ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "alcool",
          question:
            "Avez-vous déjà vomi de la bile ou du sang à cause de l’alcool ?",
          answers: { oui: -3, non: 0 },
        },
        {
          categorie: "drogue",
          question: "Avez-vous déjà drogué vos parents ?",
          answers: { régulièrement: -5, "quelque fois": -2, jamais: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà parlé de vos pratiques sexuelles au boulot ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "alcool",
          question:
            "Vous êtes vous déjà fait sortir d’un bar" +
            " ou d’une boîte de force ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "alcool",
          question:
            "Avez-vous déjà participé à un barathon ? " +
            "(tous les bars d’une ville ou d’une rue)",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "alcool",
          question: "En moyenne, combien de fois buvez-vous par semaine",
          answers: {
            "presque tous les jours": -8,
            "4 fois": -5,
            "3 fois": -3,
            "2 fois": -2,
            "Une fois ou moins souvent": -1,
            jamais: 0,
          },
        },
        {
          categorie: "alcool",
          question:
            "Vous êtes-vous déjà endormi(e) ou évanoui(e) " +
            "dans un bar ou une boîte ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "alcool",
          question: "Avez-vous déjà fait un coma éthylique ?",
          answers: { oui: -5, non: 0 },
        },
        {
          categorie: "drogue",
          question: "Avez-vous déjà fumé une cigarette ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "drogue",
          question: "Avez-vous déjà fumé un joint ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "drogue",
          question: "Avez-vous déjà coulé une douille ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "alcool",
          question: "Avez-vous déjà bu dès le matin ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "drogue",
          question: "Avez-vous déjà pris de la drogue dès le matin ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "alcool",
          question: "Quelle a été votre plus longue période bourré(e) ?",
          answers: {
            "48h ou +": -4,
            "24h durant": -4,
            "toute une nuit": -2,
            "4h ou moins": -1,
            "je ne bois jamais": 0,
          },
        },
        {
          categorie: "morale",
          question:
            "Possédez-vous une bible ou un autre " +
            "livre saint (coran, torah, ...) ?",
          answers: { "j’en ai plusieurs": 4, oui: 2, non: 0 },
        },
        {
          categorie: "morale",
          question:
            "Avez-vous déjà été à l’église volontairement " +
            "(ou dans un autre lieux saint) ?",
          answers: { souvent: 8, quelquefois: 2, jamais: 0 },
        },
        {
          categorie: "drogue",
          question:
            "Avez-vous déjà essayer une drogue dure (cocaine, héroine, ...) ?",
          answers: { plusieurs: -8, oui: -4, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez-vous déjà fait de la prison ?",
          answers: { "+ de 6 mois": -8, "Moins de 6 mois": -4, jamais: 0 },
        },
        {
          categorie: "morale",
          question: "Avez-vous déjà blessé volontairement quelqu’un ?",
          answers: {
            "Plusieurs fois": -8,
            oui: -4,
            non: 0,
          },
        },
        {
          categorie: "drogue",
          question: "Consommez-vous des drogues régulièrement ?",
          answers: {
            "presque tous les jours": -8,
            "4 fois": -5,
            "3 fois": -3,
            "2 fois": -2,
            "Une fois ou moins souvent": -1,
            jamais: 0,
          },
        },
        {
          categorie: "drogue",
          question: "Avez-vous déjà vendu de la drogue ?",
          answers: { oui: -3, non: 0 },
        },
        {
          categorie: "drogue",
          question:
            "Avez-vous déjà vendu de la drogue " +
            "pour financer votre propre consommation ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà eu un rendez-vous amoureux ?",
          answers: { oui: 5, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà pris un bain ou une douche " +
            "avec un membre du sexe opposé ?",
          answers: { oui: 1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà payé pour le sexe ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "morale",
          question:
            "Avez-vous déjà profité de quelqu’un pendant qu’il/elle était " +
            "ivre, drogué(e), ou momentanément handicapé(e) ?",
          answers: { oui: -3, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà soulé ou drogué quelqu’un " +
            "pour en abuser sexuellement et réussi ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà été impliqué(e) dans une fellation" +
            " ou un cunnilingus ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Jusqu’à l’orgasme ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà eu un rapport anal ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà fait un 69 ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "hygiene",
          question:
            "Avez-vous déjà eu une MST (maladie sexuellement transmissible) ?",
          answers: { oui: -5, non: 0 },
        },
        {
          categorie: "hygiene",
          question: "Avez-vous déjà transmis une MST ?",
          answers: { oui: -5, non: 0 },
        },
        {
          categorie: "morale",
          question: "Volontairement ?",
          answers: { oui: -10, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles " +
            "sans utiliser de moyen de contraception ?",
          answers: { oui: 0, non: 1 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà subi ou été à l’origine d’un avortement ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles " +
            "avec plus d’une personne en même temps ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles " +
            "avec plus de deux personnes dans la même semaine ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles dans un lieu public ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des brûlures de tapis (brûlures dues aux " +
            "frottements sur un tapis plutôt rugueux) " +
            "lors d’une relation sexuelle ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des rapports homosexuels " +
            "(ou contraires à votre appartenance sexuelle) ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des expériences sado-masochistes " +
            "ou pratiqué le bondage ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà utilisé des sex toys ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Vous êtes-vous déjà endormi(e) ou évanoui(e) " +
            "pendant l’acte sexuel ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà été responsable de la perte de la virginité de " +
            "quelqu’un, et si oui, de combien de personnes ?",
          answers: {
            "3 ou +": -1,
            "2 personnes": -1,
            "Une personne": -1,
            non: 0,
          },
        },
        {
          categorie: "sexe",
          question:
            "Avez vous déjà répondu à un appel pendant un acte sexuel ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà acheté quelque chose dans un sex-shop ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "hygiene",
          question: "Avez-vous déjà léché un oeil, un orteil, ou une oreille ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles " +
            "avec quelqu’un de votre famille ?",
          answers: { oui: -4, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous hésité avant de répondre à la question précédente ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "hygiene",
          question: "Avez-vous déjà dormi dans les toilettes ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "hygiene",
          question:
            "Avez-vous vous-même été volontaire pour dormir à cet endroit ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "morale",
          question:
            "Est-ce que la zoophilie, la nécrophilie ou " +
            "la pédophilie vous attire ou vous excite ?",
          answers: { oui: -5, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu un coup d’une nuit ? " +
            "(une histoire d’un soir avec relation sexuelle)",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "morale",
          question:
            "Avez-vous déjà eu un coup d’une nuit, et quitté " +
            "votre partenaire sans même lui dire aurevoir ?",
          answers: { oui: -5, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles avec plus " +
            "d’une personne de la même famille ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles avec une personne qui " +
            "était déjà engagée dans une relation avec quelqu’un d’autre ?",
          answers: { oui: -3, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles avec un(e) ami(e) " +
            "de votre partenaire officiel(le) ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles avec quelqu’un qui " +
            "était beaucoup plus vieux ou plus jeune que vous ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous hésité avant de répondre à la question précédente ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles contre de l’argent ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "hygiene",
          question:
            "Avez-vous déjà mangé votre vomi ou celui de quelqu’un d’autre ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "hygiene",
          question:
            "Avez-vous déjà mis des aliments dans votre " +
            "nez juste pour rigoler ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "hygiene",
          question:
            "Avez-vous déjà participé à une douche dorée " +
            "(uriner sur quelqu’un d’autre ou se faire uriner dessus " +
            "par quelqu’un d’autre) ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà été voyeur(euse) ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Combien de fois par semaine regardez-vous des films " +
            "ou des images pornographique ?",
          answers: {
            "3 fois ou +": -3,
            "2 fois": -2,
            "Une fois ou moins souvent": -1,
            jamais: 0,
          },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà simulé un orgasme ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez-vous déjà posé un lapin à quelqu’un ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Etes-vous déjà sorti(e) avec quelqu’un juste pour le sexe ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles sur le pouce " +
            "(entre deux cours, dans la rue, le tout dans l’urgence, " +
            "sans préliminaires ou presque) ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez-vous déjà travaillé pour une oeuvre charitative ?",
          answers: { oui: 8, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez vous déjà tricher pendant un examens ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "hygiene",
          question:
            "Avez vous déjà fait boire votre urine à quelqu’un d’autre ?",
          answers: { oui: -3, "Oui, avec du jus de fruit": -5, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez vous déjà détruit un bien public ?",
          answers: { oui: -2, "Ça m’arrive souvent": -3, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez vous déjà volé un(e) ami(e) ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez-vous déjà volé dans un magasin/supermarché ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "alcool",
          question: "Avez-vous déjà volé de l’alcool (y compris dans un bar) ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "hygiene",
          question: "Vous êtes-vous déjà uriné dessus quand vous êtiez ivre ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles pour gagner un pari ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question: "Avez-vous déjà exhibé vos parties génitales en public ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles " +
            "pour avoir un travail ou une promotion ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez-vous déjà été infidèle ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles " +
            "avec le/la partenaire d’un(e) de vos ami(e)s ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "hygiene",
          question: "Avez-vous déjà vomi dans un lieu public ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Vous êtes-vous déjà réveillé(e) en vous demandant où vous êtiez ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Vous êtes-vous déjà réveillé(e) en vous demandant où vous êtiez " +
            "et qui était la personne à côté de vous ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Vous êtes-vous déjà posé des questions sur la vie " +
            "sexuelle des personnages de dessins-animés ?",
          answers: { oui: -1, non: 1 },
        },
        {
          categorie: "hygiene",
          question: "Avez-vous un tatouage ?",
          answers: { oui: -1, non: 0 },
        },
        {
          categorie: "sexe",
          question:
            "Avez-vous déjà eu des relations sexuelles " +
            "que vous regrettez maintenant ?",
          answers: { oui: -1, non: 1 },
        },
        {
          categorie: "morale",
          question:
            "Avez-vous effectué un des actes de ce test " +
            "exprès pour améliorer votre score ?",
          answers: { oui: -2, non: 0 },
        },
        {
          categorie: "morale",
          question: "Avez-vous répondu honnêtement aux question ?",
          answers: { oui: 1, non: -1 },
        },
      ],
      /**
       * Numéro de la question courante
       * @type {number}
       */
      questionIndex: 0,
    };
  },
  created() {},
  methods: {
    // On détermine le nom du niveau atteint dans chaque catégorie
    levelDeterminator() {
      // eslint-disable-next-line no-unused-vars
      for (const [type, data] of Object.entries(this.scores)) {
        for (let v in this.levels) {
          if (data.value >= this.levels[v]) {
            data.label = v;
            break;
          }
        }
      }
    },
    /**
     * Incrémente le compteur associé à la réponse de l'utilisateur
     * @param {Event} e La réponse choisie.
     */
    submit(reponse) {
      const points = this.questions[this.questionIndex].answers[reponse];
      // On incrémente le kharma
      this.scores.kharma.value += points * this.coefs["kharma"];
      // On incrémente le score de la catégorie correpondante à la question courante
      this.scores[this.questions[this.questionIndex].categorie].value +=
        points * this.coefs[this.questions[this.questionIndex].categorie];
      this.levelDeterminator();
      this.questionIndex++;
    },
  },
});
</script>

<style scoped lang="sass">
@import "../assets/css/testAlternatif.scss";
</style>
