<template>
  <h1 class="cover-heading">
    {{ $t("home-page.title") }}
  </h1>
  <p>
    <strong>
      {{ $t("home-page.subtitle") }}
    </strong>
  </p>
  <p />
  <p class="lead">
    <strong>{{ $t("home-page.beware") }}</strong>
  </p>
  <div>
    <p>
      👇
      Protection anti-drogue <span style="font-family: NeutonExtraBold; font-size: 22px">Griffor</span>
      👇
    </p>
    <a href="https://cupote.fr" target="blank_"><img
      src="/images/cupote.webp"
      alt="Protection pour verre, la capote pour verre"
      class="img-encart"
    ></a>
  </div>
  <p
    id="nombreParticipant"
    class="lead"
  >
    {{ $t("home-page.already") }}
    {{ numberWithSpaces(liveCount) }}
    {{ $t("home-page.tests-made") }}
  </p>
  <div class="lead">
    <!--Start the Griffor button-->
    <div class="home-page-btn">
      <a
        id="startBtn"
        style="font-size: inherit"
        class="btn btn-lg btn-warning"
        @click="$router.push({ name: 'test', params: { lang: $i18n.locale } })"
      >
        {{ $t("home-page.begin-griffor-btn") }}
      </a>
    </div>
    <!--Continue test button-->
    <div class="home-page-btn">
      <a
        v-if="savedTestState"
        id="continueBtn"
        style="font-size: inherit"
        class="btn btn-lg-secondary btn-primary"
        @click="
          $router.push({
            name: 'test',
            params: { lang: $i18n.locale, savedTestState: savedTestState },
          })
        "
      >
        <span class="glyphicon glyphicon-time" />
        {{ $t("home-page.continue-btn") }}
      </a>
    </div>
    <!--Start the Boudah button-->
    <div class="home-page-btn">
      <a
        id="startBoudahBtn"
        style="font-size: inherit"
        class="btn btn-lg-secondary btn-success"
        @click="
          $router.push({
            name: 'testAlternatif',
            params: { lang: $i18n.locale },
          })
        "
      >
        {{ $t("home-page.begin-boudah-btn") }}
      </a>
    </div>
    <!--Start the Michou test button-->
    <div class="home-page-btn">
      <a
        id="startMichouBtn"
        style="font-size: inherit"
        class="btn btn-lg-secondary btn-success"
        @click="
          $router.push({
            name: 'testMichou',
            params: { lang: $i18n.locale },
          })
        "
      >
        {{ $t("home-page.begin-michou-btn") }}
      </a>
    </div>
  </div>

  <p>
    {{ $t("home-page.ad-warning.warning") }}
    <br>
    {{ $t("home-page.ad-warning.our-test") }}
    <b>{{ $t("home-page.ad-warning.always") }}</b>
    {{ $t("home-page.ad-warning.free") }}
  </p>

  <p>
    {{ $t("traducteur") }}
  </p>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      savedTestState: null,
      liveCount: "...",
    };
  },
  mounted() {
    window.ezstandalone = window.ezstandalone || {};
    // eslint-disable-next-line no-undef
    ezstandalone.cmd = ezstandalone.cmd || [];
    // eslint-disable-next-line no-undef
    ezstandalone.cmd.push(function() {
        // eslint-disable-next-line no-undef
        ezstandalone.displayMore(114, 115);
    });
  },
  beforeCreate() {
    axios
      .get(process.env.VUE_APP_BACKEND_API + `/liveCount`)
      .then((response) => {
        console.log(response.data);
        this.liveCount = response.data;
      })
      .catch((e) => {
        console.log("erreur" + e);
        this.liveCount = "...";
      });
  },
  created() {
    this.savedTestState = this.getTestState();
  },
  methods: {
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    /**
     * Retrieve test state from local storage if it exist
     * TTL of this cache can't be a different day than the one when the test was passed
     * @returns {Object|null}
     */
    getTestState() {
      if (localStorage.testState) {
        const retrievedTestState = JSON.parse(localStorage.testState);
        const date = new Date();
        const todayDate =
          date.getDay() + ":" + date.getMonth() + ":" + date.getFullYear();
        if (todayDate === retrievedTestState.date) {
          return localStorage.testState;
        } else {
          localStorage.removeItem("testState");
        }
      }
      return null;
    },
  },
};
</script>

<style>
#ad-image {
  margin-bottom: 1vh;
  width: 95%;
  height: auto;
}
</style>
